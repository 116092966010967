/* @import 'node_modules/css-star-rating/css/star-rating.min.css'; */

.rating.disabled .star-container .star{
  opacity:1 !important;
}
.rating.disabled .label-value{
  opacity:1 !important;
}
strong {
  font-weight: 900 !important;
}

.btn-waiting-sent {
  background-color: #0078d4 !important;
}
.btn-draft {
  background-color: #0078d4 !important;
}
.btn-demande_paiement_courrier {
  background-color: #537086 !important;
}
.btn-demande_paiement_voie_electronique {
  background-color: #b14a8e !important;
}
.btn-demande_paiement_extranet {
  background-color: #069b93 !important;
}
.btn-soldee {
  background-color: #2e2e2e !important;
}
/* You can add global styles to this file, and also import other style files */
.dropdown-menu li:not(:last-child) {
    border-bottom: 1px solid gainsboro;
  }
  
  .dropdown-menu .date {
    font-size: 10px;
    color: gray;
  }
  
  .dropdown-menu .author {
    font-size: 14px;
    color: gray;
  }

  .bold {
    font-weight: bold;
}

select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}


.togglebutton label input[type=checkbox]:checked+.toggle {
  background-color: rgba(145, 198, 2, 0.7) !important;
}

.btn-waiting-sent {
  background-color: #0078d4 !important;
}

/* status selector */
.dropdown-item.active, .dropdown-item:active.span, .dropdown-item:hover {
  background-color: #91c602 !important;
  color: white !important;
  cursor: pointer !important;
}

.dropdown-menu{
  padding: 0px !important;
}

.dropdown-menu .dropdown-item, .dropdown-menu li > a {
  margin: 0px !important;
}

.dropdown-menu .dropdown-item, .dropdown-menu li > a > span {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.semi-bold {
  font-weight: 500;
}

.btn.btn-blue {
  background-color: #1facff;
}

/* edit-frequencies */
.cotation {
  color: #b4d758;
  font-weight: 600;
}
.cotation .btn-info.btn-cotation {
  padding: 7px;
}
.card .card-content {
  padding: 15px 10px;
  position: relative;
}
.well {
  min-height: 20px;
  padding: 19px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}

.modal-backdrop {
  display: none !important;
}

.modal{
  background-color:rgba(0, 0, 0, 0.4) !important;
}

#add {
  width: 41px !important;
  height: 41px !important;
  line-height: 41px !important;
}
/* Message */
.msg-content {
  max-width: 200px !important;
  display: inline-block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.msg-content * {
  max-width: 200px !important;
  display: inline-block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.btn.btn-simple,
.btn.btn-default.btn-simple,
.navbar .navbar-nav>li>a.btn.btn-simple,
.navbar .navbar-nav>li>a.btn.btn-default.btn-simple {
  background-color: transparent;
  color: #999999;
  box-shadow: none;
}

.btn.btn-simple:hover,
.btn.btn-simple:focus,
.btn.btn-simple:active,
.btn.btn-default.btn-simple:hover,
.btn.btn-default.btn-simple:focus,
.btn.btn-default.btn-simple:active,
.navbar .navbar-nav>li>a.btn.btn-simple:hover,
.navbar .navbar-nav>li>a.btn.btn-simple:focus,
.navbar .navbar-nav>li>a.btn.btn-simple:active,
.navbar .navbar-nav>li>a.btn.btn-default.btn-simple:hover,
.navbar .navbar-nav>li>a.btn.btn-default.btn-simple:focus,
.navbar .navbar-nav>li>a.btn.btn-default.btn-simple:active {
  background-color: transparent;
  color: #999999;
  box-shadow: none;
}

.btn.btn-primary.btn-simple,
.navbar .navbar-nav>li>a.btn.btn-primary.btn-simple {
  background-color: transparent;
  color: #91c602;
  box-shadow: none;
}

.btn.btn-primary.btn-simple:hover,
.btn.btn-primary.btn-simple:focus,
.btn.btn-primary.btn-simple:active,
.navbar .navbar-nav>li>a.btn.btn-primary.btn-simple:hover,
.navbar .navbar-nav>li>a.btn.btn-primary.btn-simple:focus,
.navbar .navbar-nav>li>a.btn.btn-primary.btn-simple:active {
  background-color: transparent;
  color: #91c602;
  box-shadow: none;
}

.width300 {
  width: initial !important;
}

quill-editor {
  width: 100%;
}
#textarea-emoji {
  top: 40px
}

.ql-snow {
  background-color: white;
}

.ql-editor {
  min-height: 200px !important; 
}

/* Message */

.messages h6 {
  color: #91c602;
  margin: 0;
  font-size: 0.9rem !important;
  font-weight: 300 !important;
}

.messages .card {
  margin: 0;
}

.messages .col-md-6.pull-left.not-checked div.card div.card-body{
  background-color: #f8915642;
  border-radius: 6px;
}

.messages .col-md-6.pull-left.checked div.card div.card-body{
  background-color: #e9f4cb;
  border-radius: 6px;
}

.messages .col-md-6.pull-right div.card div.card-body{
  background-color: #e6e5e5;
  border-radius: 6px;
}

.messages .col-md-6 .checkbox label {
  color: #658608;
  font-weight: 400;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color:#91c602 !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: white !important;
}

/* NEW ANGULAR MATERIAL 16 */

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus.mdc-list-item,
.mat-mdc-option.mat-mdc-option-active.mdc-list-item,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background: #91c602 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled){
  background-color: white !important;
}

.mat-mdc-floating-label {
  font-size: 14px !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: #91c602 !important;
  background-color:#91c602 !important;
}

.mat-mdc-checkbox {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #91c602 !important;
  --mdc-checkbox-selected-hover-icon-color: #91c602 !important;
  --mdc-checkbox-selected-icon-color: #91c602 !important;;
  --mdc-checkbox-selected-pressed-icon-color: #91c602 !important;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #91c602 !important;
  --mdc-checkbox-selected-hover-state-layer-color: #91c602 !important;
  --mdc-checkbox-selected-pressed-state-layer-color: #91c602 !important;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

/* .mat-mdc-icon-button svg, .mat-mdc-icon-button img {
  width: 16px !important;
  height: 16px !important;
} */

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #91c602 !important;
  --mdc-switch-selected-handle-color: #91c602 !important;
  --mdc-switch-selected-hover-state-layer-color: #91c602 !important;
  --mdc-switch-selected-pressed-state-layer-color: #91c602 !important;
  --mdc-switch-selected-focus-handle-color: rgba(145, 198, 2, 0.1) !important;;
  --mdc-switch-selected-hover-handle-color:  rgba(145, 198, 2, 0.1) !important;;
  --mdc-switch-selected-pressed-handle-color:  rgba(145, 198, 2, 0.1) !important;;
  --mdc-switch-selected-focus-track-color: rgba(145, 198, 2, 0.1) !important;;
  --mdc-switch-selected-hover-track-color: rgba(145, 198, 2, 0.1) !important;;
  --mdc-switch-selected-pressed-track-color: rgba(145, 198, 2, 0.1) !important;;
  --mdc-switch-selected-track-color: rgba(145, 198, 2, 0.1) !important;;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color:rgba(0, 0, 0, 0.6) !important;
}

.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: rgba(0, 0, 0, 0.6) !important;
}

/* .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-left: 5px !important;
} */
/* .mdc-text-field {
  padding-left: 5px !important;
} */
.mdc-list-item--selected .mdc-list-item__primary-text, .mdc-list-item--activated .mdc-list-item__primary-text {
  color: white !important;
}

/* .mat-mdc-option {
  min-height: 40px !important;
} */

.mat-mdc-paginator-page-size-select {
  width: 70px !important;
}

.mat-mdc-form-field-focus-overlay{
  background-color: white !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: white !important;
}

.mat-mdc-select-value-text {
  font-size:14px !important;
}

.mdc-list-item__overline-text {
  color: white !important;
}

.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: #91c602 !important;
}



.btn.btn-info {
  color: #fff !important;
  background-color: #00bcd4 !important;
  border-color: #00bcd4 !important;
}

button.btn.btn-danger, .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger, .btn.btn-danger {
  color: #fff  !important;;
  background-color: #f44336  !important;;
  border-color: #f44336  !important;;
  
}

.table .td-actions .btn {

  min-height: 35px !important;
  min-width: 35px !important;
 
}

.mdc-text-field--disabled .mdc-text-field__input {
  color: black !important;
  font-size: 14px !important;;
}

.mat-mdc-form-field {
  display: flex !important;
}

.mat-button.btn.btn-warning, .mat-raised-button.btn.btn-warning, .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning, .btn.btn-warning{

    color: #fff !important; 
    background-color: #ff9800 !important;
    border-color: #ff9800 !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: #91c602 !important;
}

.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: #91c602 !important;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  min-height: 56px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled){
  min-height: 56px !important;
}

.mat-mdc-checkbox label {
  margin: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-state-layer-size)) / 2);
}

#factu > div > button {
  width: 320px !important;
}

button.dropdown-toggle.disabled.btn.btn-xs.btn-soldee::after{
  display: none !important;
}

.mat-mdc-text-field-wrapper {
  width: 100% !important;
}

.modal {
  z-index: 100 !important;
}

.ql-snow .ql-tooltip  {
  left:0px !important;
}